import { AlkoCulture, AlkoRoles } from '@al-ko/types';
import { Injectable } from '@angular/core';
import { OAuthErrorEvent, OAuthService } from 'angular-oauth2-oidc';
import { jwtDecode } from 'jwt-decode';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private jwtToken: AlkoJwtToken = undefined;

  constructor(private oauthService: OAuthService) {
    // this.oauthService.setupAutomaticSilentRefresh();

    if (this.oauthService.hasValidAccessToken()) {
      this.jwtToken = jwtDecode(this.oauthService.getAccessToken());
    }

    this.oauthService.events.subscribe((event) => {
      if (event instanceof OAuthErrorEvent) {
        // console.error('oauth-error: ', event);
      } else if (event.type == 'token_received') {
        this.jwtToken = jwtDecode(this.oauthService.getAccessToken());
      } else if (event.type == 'token_refreshed') {
        console.log('! Token refreshed !');
        this.jwtToken = jwtDecode(this.oauthService.getAccessToken());
      } else {
        console.warn('unknonwn oauth-event-type', event.type);
      }
    });
  }

  public isAlkoEmployee(): boolean {
    if (this.jwtToken !== undefined) {
      return this.jwtToken.role.includes(AlkoRoles.AlkoEmployee);
    }
    return false;
  }

  public isDealer(): boolean {
    if (this.jwtToken !== undefined) {
      return this.jwtToken.role.includes(AlkoRoles.Dealer);
    }
    return false;
  }

  public isServiceParnter(): boolean {
    if (this.jwtToken !== undefined) {
      return this.jwtToken.role.includes(AlkoRoles.ServicePartner);
    }
    return false;
  }

  public isServiceInternal(): boolean {
    if (this.jwtToken !== undefined) {
      return this.jwtToken.role.includes(AlkoRoles.ServiceInternal);
    }
    return false;
  }

  public isAdmin(): boolean {
    if (this.jwtToken !== undefined) {
      return this.jwtToken.role.includes(AlkoRoles.Admin);
    }
    return false;
  }

  public getUserName(): string {
    return this.jwtToken?.email;
  }

  public getEmail(): string {
    return this.jwtToken?.email;
  }

  public getSapCustomerId(): string {
    return this.jwtToken?.alkoCustomerId;
  }

  public getAlkoCulture(): string {
    return this.jwtToken?.alkoCulture;
  }

  public getLocaleFromAlkoCulture(alkoCulture: string): string {
    return AlkoCultures[alkoCulture] || alkoCulture;
  }

  public hasRole(roleName: string): boolean {
    return this.jwtToken?.role.includes(roleName);
  }
}

const AlkoCultures = {
  AT: 'de-AT',
  AU: 'en-AU',
  CZ: 'cs-CZ',
  DE: 'de-DE',
  DK: 'da-DK',
  ES: 'es-ES',
  FI: 'fi-FI',
  FR: 'fr-FR',
  GB: 'en-GB',
  HR: 'hr-HR',
  HU: 'hu-HU',
  IT: 'it-IT',
  LV: 'lv-LV',
  NL: 'nl-NL',
  NO: 'nb-NO',
  NZ: 'en-NZ',
  PL: 'pl-PL',
  RS: 'sr-Cyrl-RS',
  RU: 'ru-RU',
  SE: 'sv-SE',
  SI: 'sl-SI',
  SK: 'sk-SK',
  UA: 'uk-UA',
  UK: 'uk-UA',
};

// tslint:disable-next-line: interface-over-type-literal
type AlkoJwtToken = {
  nbf: number;
  exp: number;
  auth_time: number;
  email_verified: boolean;
  iss: string;
  aud: string;
  client_id: string;
  sub: string;
  idp: string;
  ['AspNet.Identity.SecurityStamp']: string;
  preferred_username: string;
  name: string;
  email: string;
  alkoCustomerId: string;
  alkoCulture: string;
  AlkoRoles: string[];
  role: string[];
  scope: string[];
  amr: string[];
};
